import React from "react"

import CenterColumn from "../../components/Bulma/CenterColumn"
import ImageMeta from "../../components/ImageMeta"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import Button from "@bit/azheng.joshua-tree.button"

const Exparel = ({ post }) => {
  if (!post.hasThisSection) return <></>

  return (
    <CenterColumn
      className="home-exparel"
      leftColWidth={2}
      rightColWidth={2}
      centerColumn={
        <div className="column" data-aos="fade-up" data-aos-duration="1200">
          <ImageMeta cloudName="nuvolum" publicId={post.imageId} responsive />
          <MarkdownViewer markdown={post.blurb} />
          <Button
            href={post.button.href}
            buttonText={post.button.buttonText}
            contained
          />
        </div>
      }
    />
  )
}

export default Exparel
